import ConfemyLogo from '../../assets/2.png';
import { getFormattedDateInTz2 } from '../../utility/commonUtil';
import CreditsIcon from '../icons/CreditsIcon';
export default function CourseRecieptPrint({ bookingDetails }) {
  console.log('course print runn', bookingDetails);
  return (
    <div className="p-12">
      <div
        // ref={componentRef}
        className="grid-table-wrap"
        style={{
          border: '.5px solid #ced9de',
          borderRadius: 4,
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.08)',
          padding: '24px 4px 0px 4px',
        }}
      >
        <div className="p-24 mb-8">
          <div className="flex-vc mb-24 mr-4">
            <img className="icon-lg" src={ConfemyLogo} />
            <h4 className="mt-4 ml-4">Confemy Booking Details</h4>
          </div>
          <div>
            <h4 className="mb-12">{bookingDetails?.media?.title}</h4>

            <div>
              <p className="mb-8">
                <span className="caption-1-heavy-cblack">Status: </span>
                {bookingDetails?.mediaBooked === 1 ? (
                  <span
                    style={{
                      color: '#4CB944',
                    }}
                    className="caption-2-regular-gray3"
                  >
                    Booking Confirmed
                  </span>
                ) : (
                  <span
                    style={{ color: '#bb2124' }}
                    className="caption-2-regular-gray3"
                  >
                    Cancelled
                  </span>
                )}
              </p>
            </div>
            <div className="caption-2-regular-gray3 preview-text mb-16">
              <p className="mb-8">
                <span className="caption-1-heavy-cblack">
                  Booking number :{' '}
                </span>
                <span>{bookingDetails?.serialNumber} </span>
              </p>
              <p>
                <span className="caption-1-heavy-cblack">Booking Date : </span>
                <span>
                  {' '}
                  {getFormattedDateInTz2(
                    bookingDetails?.bookingDate,
                    bookingDetails?.timezone
                  )}
                </span>
              </p>
            </div>
            <div className="mb-12">
              <div className="flex-vc mb-8">
                <CreditsIcon className="icon-sm mr-12" />
                <span className="caption-2-regular-gray3">
                  {bookingDetails?.media?.credits?.length > 0
                    ? `${bookingDetails?.media?.credits[0]?.quantity} credits`
                    : 'Credits not offered'}
                </span>
              </div>
            </div>
            <p>
              <span className="caption-1-heavy-cblack">
                Total Booking Amount:{' '}
                {bookingDetails?.price === 0
                  ? 'FREE'
                  : `${bookingDetails?.media?.currencySymbol} ${bookingDetails?.price}`}
              </span>
            </p>
          </div>

          <div>
            <div className=" mb-18 mt-16">
              <div className="abd-grid-item mt-16 ">
                <p className="mb-8">
                  <span className="caption-1-heavy-cblack">First Name: </span>
                  <span className="caption-2-regular-gray3">
                    {bookingDetails?.user?.firstName}
                  </span>
                </p>
                <p className="mb-8">
                  <span className="caption-1-heavy-cblack">Last Name: </span>
                  <span className="caption-2-regular-gray3">
                    {bookingDetails?.user?.lastName}
                  </span>
                </p>
                <p className="mb-8">
                  <span className="caption-1-heavy-cblack">Email: </span>
                  <span className="caption-2-regular-gray3">
                    {bookingDetails?.user?.email}
                  </span>
                </p>

                <p className="caption-2-regular-gray3 mb-8">
                  <span className="caption-1-heavy-cblack"> Price: </span>
                  <span className="caption-2-regular-gray3">
                    {bookingDetails?.organiserAmount > 0
                      ? `${bookingDetails?.media?.currencySymbol}`
                      : null}
                  </span>
                  <span className="caption-2-regular-gray3">
                    {' '}
                    {bookingDetails?.organiserAmount > 0
                      ? bookingDetails?.organiserAmount
                      : 'FREE'}
                  </span>
                </p>
                <p className="caption-2-regular-gray3 mb-4">
                  <span className="caption-1-heavy-cblack">
                    Service Charges:{' '}
                  </span>
                  <span className="caption-2-regular-gray3">
                    {bookingDetails?.convFee > 0
                      ? `${bookingDetails?.media?.currencySymbol} `
                      : null}
                  </span>
                  <span className="caption-2-regular-gray3">
                    {bookingDetails?.convFee}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
