import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertAction } from "../../../redux/alert/alertAction";
import { loadUserCustomConferenceCreditsTypeAction } from "../../../redux/user-profile/userProfileAction";
import api from "../../../utility/api";
import Dialogue from "../../dialogue/Dialogue";
import AddIcon from "../../icons/AddIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import EditIcon from "../../icons/EditIcon";
import ModalX from "../../modal/ModalX";
import ConferenceCreditsTypeForm from "./UserConfemyCreditTypeForm";

import Select from "react-select";
import { loadMyOrganizationsSelectListAction } from "../../../redux/organization/myOrganizationsAction";
import { getValue, scrollToTop } from "../../../utility/commonUtil";
import SearchIcon from "../../icons/SearchIcon";
import { customSelectStyle3 } from "../../reselect/selectUtil";
import { renderRowStyle } from "../../../utility/commonUtil";
import "./userConfemyCreditTypeTable.scss";
import Loader from "../../loader/Loader";

const UserConfemyCreditsTypeTable = () => {
  const creditTypesList = useSelector(
    (state) => state.userProfile.userCustomConferenceCreditsType
  );

  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [creditToDelete, setCreditToDelete] = useState(null);
  const [creditToEdit, setCreditToEdit] = useState(null);
  const [showConferenceCreditTypeForm, setShowConferenceCreditForm] =
    useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [searchFilter, setSearchFilter] = useState("all");

  const user = useSelector((state) => state.auth.user);
  const organizationsListForSelect = useSelector(
    (state) => state.myOrganizations.organizationsListForSelect
  );
  const dispatch = useDispatch();

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };

  //  on clicking a row cell open edit
  function onRowClick(credit) {
    setCreditToEdit(credit);
    setOpenEditModal(true);
  }

  const getCustomConferenceCreditsType = async (userId, text, filter) => {
    try {
      const response = await api.get(
        `/conferences/credits/userCustomCredits/users/${userId}`
      );

      dispatch(
        loadUserCustomConferenceCreditsTypeAction(
          response.data.data.confrenceCredits
        )
      );
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  const deleteConferenceCreditType = async (creditId) => {
    try {
      setIsLoading(true);
      const response = await api.delete(
        `/conferences/credits/userCustomCredit/${creditId}?userId=${user?._id}`
      );
      if (response) {
        dispatch(
          loadUserCustomConferenceCreditsTypeAction(response.data.data.credits)
        );
        setOpenDialogue(false);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  const loadMyOrgnizations = async (id) => {
    const url = `organizations/users/${id}?orgForConference=true`;
    try {
      const response = await api.get(url);

      if (response) {
        dispatch(
          loadMyOrganizationsSelectListAction(response.data?.data?.organization)
        );
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  const options2 = [
    { value: "all", label: "All" },
    { value: "user", label: "User" },
    ...organizationsListForSelect,
  ];

  const searchCredit = (credit) => {
    let listToSearch = [];
    if (searchFilter === "all") {
      listToSearch = creditTypesList;
    } else if (searchFilter === "user") {
      listToSearch = creditTypesList?.filter((item) => item.host === "user");
    } else {
      listToSearch = creditTypesList?.filter(
        (element) =>
          element?.host === "organization" &&
          element?.organizationId === searchFilter
      );
    }
    setSearchList(() =>
      listToSearch?.filter((item) =>
        item.label.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  useEffect(() => {
    searchCredit(creditTypesList);
  }, [searchText, searchFilter, creditTypesList]);

  useEffect(() => {
    loadMyOrgnizations(user?._id);
    getCustomConferenceCreditsType(user?._id);
  }, [user?._id]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <div className="myconfs-header mb-24">
        <h1>Conference Credits</h1>
        <button
          onClick={() => {
            setShowConferenceCreditForm(true);
          }}
          className="button button-green"
        >
          <div className="flex-vc">
            <AddIcon className="icon-size" fill="#fff" />
            <p className="ml-8">Add Credit</p>
          </div>
        </button>
      </div>
      <div className="two-filters-wrap mb-24">
        <div style={{ position: "relative" }} className="form-type-3">
          <input
            type="text"
            id="myConfsSearchText"
            placeholder="Search Credit types"
            name="searchText"
            value={searchText}
            onChange={(e) => {
              // getCustomConferenceCreditsType(user?._id);
              setSearchText(e.target.value);
            }}
            autoComplete="off"
          />
          <i
            className={
              searchText?.length > 0 ? "display-none" : "conf-search-input-icon"
            }
          >
            <SearchIcon width="2.4rem" height="2.4rem" />
          </i>
        </div>

        <Select
          styles={customSelectStyle3}
          value={getValue(options2, searchFilter, false)}
          options={options2}
          name="filter"
          onChange={(option) => {
            setSearchFilter(option.value);
          }}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="grid-table-wrap">
          <ul className="grid-table-3-300 ucct-gridtable">
            <li>Conference Credit Type</li>
            <li>Belongs To</li>
            <li>Actions</li>

            {searchList?.length > 0 &&
              searchList?.map((credit, indx) => {
                return (
                  <Fragment key={credit?.value}>
                    <li
                      onMouseEnter={() => handleMouseEnter(credit?.value)}
                      onMouseLeave={() => handleMouseLeave(credit?.value)}
                      style={renderRowStyle(
                        indx,
                        credit?.value,
                        isHover,
                        rowId
                      )}
                      onClick={() => onRowClick(credit)}
                    >
                      {credit.label}
                    </li>
                    <li
                      onMouseEnter={() => handleMouseEnter(credit?.value)}
                      onMouseLeave={() => handleMouseLeave(credit?.value)}
                      style={renderRowStyle(
                        indx,
                        credit?.value,
                        isHover,
                        rowId
                      )}
                      onClick={() => onRowClick(credit)}
                    >
                      {credit?.host === "organization"
                        ? credit.organization
                        : "Self"}
                    </li>
                    <li
                      onMouseEnter={() => handleMouseEnter(credit?.value)}
                      onMouseLeave={() => handleMouseLeave(credit?.value)}
                      style={renderRowStyle(
                        indx,
                        credit?.value,
                        isHover,
                        rowId
                      )}
                      // onClick={() => onRowClick(credit)}
                    >
                      <div
                        style={{ justifyContent: "flex-end" }}
                        className="flex-vc"
                      >
                        <i
                          className="mr-4"
                          onClick={(e) => {
                            e.stopPropagation();
                            onRowClick(credit);
                          }}
                        >
                          <EditIcon className="icon-size" fill="#08415c" />
                        </i>
                        <i
                          className="ml-4"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCreditToDelete(credit);
                            setOpenDialogue(true);
                          }}
                        >
                          <DeleteIcon className="icon-size" />
                        </i>
                      </div>
                    </li>
                  </Fragment>
                );
              })}
          </ul>
          {showConferenceCreditTypeForm && (
            <ModalX
              onDismiss={() => setShowConferenceCreditForm(false)}
              open={showConferenceCreditTypeForm}
            >
              <div className="modalx-container-sm mb-40">
                <ConferenceCreditsTypeForm
                  editMode={false}
                  setShowConferenceCreditForm={setShowConferenceCreditForm}
                />
              </div>
            </ModalX>
          )}
          {openEditModal && (
            <ModalX
              onDismiss={() => {
                setCreditToEdit(null);
                setOpenEditModal(false);
              }}
              open={openEditModal}
            >
              <div className="modalx-container-sm mb-40">
                <ConferenceCreditsTypeForm
                  editMode={openEditModal}
                  setEditMode={setOpenEditModal}
                  editData={creditToEdit}
                />
              </div>
            </ModalX>
          )}
          {openDialogue && (
            <Dialogue
              msg="Are you sure you want to delete the conference credit type?"
              title="Confirm Delete !!"
              closeDialogue={() => {
                setOpenDialogue(false);
                setCreditToDelete(null);
              }}
              yesAction={() =>
                deleteConferenceCreditType(creditToDelete?.value)
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UserConfemyCreditsTypeTable;
