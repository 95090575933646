import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import api from '../../utility/api';
import { alertAction } from '../../redux/alert/alertAction';
import RadioFilledIcon from '../../components/icons/RadioFilled';
import RadioIcon from '../../components/icons/RadioIcon';
import MyConfsCard from '../../components/conference/MyConfsCard';
import './confBookingByAttendeePage.scss';
import Loader from '../../components/loader/Loader';
import ButtonWithLoader from '../../components/button/ButtonWithLoader';

export default function ConfBookingByAttendeePage() {
  const [selectedTicketId, setSelectedTicketId] = useState('');
  const [conf, setConf] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectedTicket = conf?.tickets?.find(
    (obj) => obj?._id === selectedTicketId
  );

  const handleChange = (event) => {
    setSelectedTicketId(event.target.value);
  };

  const { confId } = useParams();
  // const confId = '66e340dc9874c07d10dc0c70';

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const buyCourse = async (e) => {
    if (!selectedTicketId) {
      dispatch(alertAction('Select a ticket to purchase', 'danger'));
      return;
    }
    const url = 'conferences/bookings';
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formData = {
      bookingDetails: {
        userId: user?._id,
        ticketId: selectedTicketId,
        conferenceId: conf?._id,
        timezone: timezone,
        bookingDate: new Date().toISOString(),
      },
    };

    try {
      setIsSubmitting(true);
      const response = await api.post(url, formData);
      if (response) {
        if (!response.data.data.bookingStatus) {
          if (response?.data?.data?.paymentIntent?.success) {
            window.location.href =
              response?.data?.data?.paymentIntent?.data?.instrumentResponse?.redirectInfo?.url;
          }
        } else {
          // setIsSubmitting(false);
          navigate(
            `/conference-receipt/${response?.data?.data?.transactionId}`
          );
          // navigate('/free-booking-status', {
          //   state: {
          //     bookingStatus: response.data.data.coursePurchased,
          //   },
          // });
        }
      }
    } catch (err) {
      setIsSubmitting(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const loadConferenceDetails = async (Id) => {
    try {
      const response = await api.get(`common/conferences/${Id}`);
      setConf(response?.data?.data?.conferences);
      setIsLoading(false);
    } catch (err) {
      if (err) {
        dispatch(
          alertAction(
            err?.response?.data?.message || 'Something went wrong',
            'danger'
          )
        );
      }
    }
  };

  useEffect(() => {
    loadConferenceDetails(confId);
  }, [confId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container pt-64">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-lg pt-64">
          <div className="mb-60">
            <h2 className="color-primary mb-24">Book Tickets</h2>
          </div>
          <div className="cbbap-container">
            <div>
              <div className="mb-32">
                <MyConfsCard
                  banner={conf?.banner}
                  title={conf?.title}
                  startDate={conf?.startDate}
                  endDate={conf?.endDate}
                  timezone={conf?.timezone}
                  city={conf?.city}
                  country={conf?.country}
                  mode={conf?.mode}
                  onCardClick={() => {}}
                />
              </div>
              <div>
                <h4 className="color-primary mb-32">
                  Choose type of ticket you want to purchase.
                </h4>
                <div className="mb-16">
                  {conf?.tickets?.map((ticket) => (
                    <div key={ticket?._id} className="mb-8">
                      <label>
                        <div className="flex-vc" style={{ gap: 16 }}>
                          <i>
                            {selectedTicketId === ticket?._id ? (
                              <RadioFilledIcon className="icon-size" />
                            ) : (
                              <RadioIcon className="icon-size" />
                            )}
                          </i>
                          <div className="flex-vc" style={{ gap: 16 }}>
                            <p className="avenir-18-500">{ticket?.name} :</p>
                            <p className="avenir-18-500">
                              <span className="mr-4">
                                {conf?.currencySymbol}
                              </span>
                              <span>{ticket?.price}</span>
                            </p>
                          </div>
                        </div>
                        <input
                          style={{ display: 'none' }}
                          type="radio"
                          value={ticket?._id}
                          checked={selectedTicketId === ticket?._id}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="cbbap-paybox" style={{}}>
              {conf?.currency && (
                <div>
                  <div>
                    <div className="mb-16">
                      <p className="body-regular-gray3 mb-8">
                        Ticket Price:{' '}
                        {selectedTicket?.price && conf?.currencySymbol && (
                          <span>
                            {conf?.currencySymbol} {selectedTicket?.price}
                          </span>
                        )}
                      </p>
                      <p className="body-regular-gray3 mb-8">
                        Service charge:{' '}
                        {selectedTicket?.price && conf?.currencySymbol && (
                          <span>
                            {conf?.currencySymbol}{' '}
                            {(selectedTicket?.price * (5 / 100)).toFixed(2)}
                          </span>
                        )}
                      </p>
                      <p className="avenir-heavy-18">
                        Subtotal:{' '}
                        {selectedTicket?.price && conf?.currencySymbol && (
                          <span>
                            {conf?.currencySymbol}{' '}
                            {selectedTicket?.price +
                              selectedTicket?.price * (5 / 100)}
                          </span>
                        )}
                      </p>{' '}
                    </div>
                    <div
                      style={{
                        width: '200px',
                      }}
                    >
                      <ButtonWithLoader
                        isSubmitting={isSubmitting}
                        text={selectedTicket?.price > 0 ? 'Pay' : 'Book'}
                        className="button button-primary"
                        handleClick={(e) => buyCourse(e)}
                        fullWidth={true}
                      />
                    </div>

                    {/* <button
                      className="button button-primary"
                      onClick={(e) => buyCourse(e)}
                      style={{
                        width: "200px",
                      }}
                    >
                      Pay
                    </button> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
