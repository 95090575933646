import { useState } from 'react';
// import PrintTicket from "./PrintTicket";
import DateIcon from '../icons/DateIcon';
import LocationIcon from '../icons/LocationIcon';
import PriceIcon from '../icons/PriceIcon';
import ModalX from '../modal/ModalX';
import AttendeeTickets from './AttendeeTickets';
import { openInNewTab } from '../../utility/commonUtil';
import api from '../../utility/api';
import {
  getFormattedDateInTz,
  getFormattedDateInTz2,
} from '../../utility/commonUtil';

import './attendeeBookingCard.scss';

export default function AttendeeBookingCard({ booking }) {
  const [openModalX, setOpenModalX] = useState(false);
  const [openTicketsModal, setOpenTicketsModal] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getLocationString = () => {
    let locationStrig = 'Location';
    if (booking?.conference?.mode?.length > 0) {
      if (
        booking?.conference?.mode?.includes('venue') &&
        booking?.conference?.location
      ) {
        locationStrig = booking?.conference?.location;
      }

      if (booking?.conference?.mode?.includes('onlineConf')) {
        locationStrig = 'Online';
      }

      if (
        booking?.conference?.mode?.includes('venue') &&
        booking?.conference?.mode?.includes('onlineConf')
      ) {
        locationStrig = `${booking?.conference?.location} & Online`;
      }
    }
    return locationStrig;
  };

  const getBookingDetails = async (bookingId) => {
    try {
      let response = await api.get(
        `/conferences/bookings/${bookingId}/${bookingId}`
      );
      setBookingDetails(response.data.data.bookingDetails);
      setIsLoading(false);
      setOpenModalX(true);
    } catch (err) {}
  };

  // const resendTicket = async (bookingId) => {
  //   try {
  //     const response = await api.get(`attendees/bookings/${bookingId}`);
  //     dispatch(alertAction(response.data.message, 'success'));
  //   } catch (err) {
  //     dispatch(
  //       alertAction(
  //         err?.response?.data?.message || 'Something went wrong',
  //         'danger'
  //       )
  //     );
  //   }
  // };

  // const printTicket = (url, id) => {
  //   localStorage.setItem('booking', JSON.stringify(id));
  //   openInNewTab(url);
  // };

  // const printReceipt = (url, id) => {
  //   localStorage.setItem('receiptData', JSON.stringify(id));
  //   openInNewTab(url);
  // };

  // const openBookingDetails = (bookingId) => {
  //   // setOpenModalX(true);
  //   getBookingDetails(bookingId);
  // };

  const openTickets = (bookingId) => {
    getBookingDetails(bookingId);
    setOpenTicketsModal(true);
  };

  return (
    <>
      <div
        className="ab-card position-relative"
        onClick={() =>
          openInNewTab(`/search-conference/${booking?.conference?._id}`)
        }
      >
        <div className="ab-card-header">
          <h4>
            {booking?.conference?.title
              ? booking?.conference?.title
              : 'Ticket title'}
          </h4>
          <div className=" ab-card-mrgin">
            <button
              className="small-button small-button-primary"
              onClick={(e) => {
                e.stopPropagation();
                openTickets(booking?._id);
                // printTicket(`/print-ticket/${booking._id}`, booking._id);
              }}
            >
              Show Tickets
            </button>
          </div>
        </div>
        <div className="ab-card-body">
          <div className="flex-vc  mb-4">
            <DateIcon className="icon-sm mr-12" />
            <span className="caption-2-regular-gray3">
              {getFormattedDateInTz(
                booking?.conference?.startDate,
                booking?.conference?.timezone
              )
                ? `Start : ${getFormattedDateInTz(
                    booking?.conference?.startDate,
                    booking?.conference?.timezone
                  )}`
                : `Ongoing event`}
            </span>
          </div>
          <div className="flex-vc  mb-4">
            <DateIcon className="icon-sm mr-12" />
            <span className="caption-2-regular-gray3">
              {getFormattedDateInTz(
                booking?.conference?.endDate,
                booking?.conference?.timezone
              )
                ? `End : ${getFormattedDateInTz(
                    booking?.conference?.endDate,
                    booking?.conference?.timezone
                  )}`
                : `Ongoing event`}
            </span>
          </div>

          <div className="flex-vc  mb-4">
            <LocationIcon className="icon-sm mr-12" />
            <span className="caption-2-regular-gray3">
              {getLocationString()}
            </span>
          </div>
          {/* <div className="flex-vc mb-4">
            <CreditsIcon className="icon-sm mr-12" />
            <span className="caption-2-regular-gray3">
              {booking?.bookingNumber > 0
                ? `Booking ID: ${booking?.bookingNumber}`
                : "Booking ID not found"}
            </span>
          </div> */}
          <div className="flex-vc  mb-4">
            <DateIcon className="icon-sm mr-12" />
            <span className="caption-2-regular-gray3">
              Booking Date:{' '}
              {getFormattedDateInTz2(booking?.bookingDate, booking?.timezone) &&
                getFormattedDateInTz2(booking?.bookingDate, booking?.timezone)}
            </span>
          </div>
          <div className="flex-vc">
            <PriceIcon className="icon-sm mr-12" />
            <span className="caption-2-regular-gray3">
              Amt Paid: {booking?.conference?.currency} -{' '}
              {booking?.paymentAmount > 0 ? booking?.paymentAmount : '0:00'}
            </span>
          </div>
        </div>
        <div className="ab-card-footer">
          <div>
            <h5 className="caption-1-regular-gray2 mb-4">Tickets Booked</h5>
            {booking?.tickets?.map((item, indx) => {
              return (
                <div key={item?._id}>
                  <p className="avenir-heavy-18">
                    {item.ticket?.name} x {1}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="user-ticket-status">
            <h6 className="caption-1-regular-gray2 mb-4 mr-28">
              Booking Number
            </h6>
            <p className="avenir-heavy-18 mr-20">
              <span>
                {booking?.bookingNumber > 0
                  ? booking?.bookingNumber
                  : 'Booking ID not found'}
              </span>
            </p>
          </div>
        </div>
        {isLoading && openModalX && <div className="ab-overlay"></div>}
        {isLoading && openTicketsModal && <div className="ab-overlay"></div>}
      </div>

      {/* {openModalX && !isLoading && (
        <ModalX
          onDismiss={() => setOpenModalX(false)}
          open={openModalX && !isLoading}
        >
          <div className="modalx-container-md mb-32 px-20">
            <AttendeeBookingDetails
              bookingDetails={bookingDetails}
              cancelTicket={cancelTicket}
              showCancelButtons={showCancelButtons}
              setShowCancelButtons={setShowCancelButtons}
              setBookingDetails={setBookingDetails}
            />
          </div>
        </ModalX>
      )} */}
      {openTicketsModal && !isLoading && (
        <ModalX
          onDismiss={() => setOpenTicketsModal(false)}
          open={openTicketsModal && !isLoading}
        >
          <div className="modalx-container-lg mb-32 px-24">
            <AttendeeTickets bookingDetails={bookingDetails} />
          </div>
        </ModalX>
      )}
    </>
  );
}
