import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { alertAction } from "../../redux/alert/alertAction";
import { createConferenceAction } from "../../redux/conference/conferenceAction";
import api from "../../utility/api";
import { openInNewTab } from "../../utility/commonUtil";
import DeleteIcon from "../icons/DeleteIcon";
import EditIcon from "../icons/EditIcon";
import ModalX from "../modal/ModalX";
import TicketForm from "../tickets/TicketForm";
import RefundPolicy from "../footer/RefundPolicy";
import Dialogue from "../dialogue/Dialogue";

export default function ConfTickets() {
  const [open, setOpen] = useState(false);
  const [openDeleteDialogue, setDeleteDialogue] = useState("");
  const [ticket, setTicket] = useState("");
  const [openRefundPolicy, setOpenRefundPolicy] = useState(false);
  const [ticketToEdit, setTicketToEdit] = useState();
  const newConference = useSelector((state) => state.conference.newConference);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function editTicket(ticket) {
    setTicketToEdit(ticket);
    setOpen(true);
  }

  function openTicketModal() {
    setOpen(true);
  }

  function closeTicketModal() {
    setTicketToEdit(null);
    setOpen(false);
  }

  async function deleteTicket() {
    const url = `conferences/${newConference?._id}/tickets/${ticket?._id}`;
    try {
      const response = await api.delete(url);
      dispatch(createConferenceAction(response.data.data.conference));
      setDeleteDialogue(false);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  }
  function handleNext() {
    navigate("/dashboard/create-conf/step-6");
  }

  return (
    <div className="mb-72">
      <p className="body-regular-gray3 mb-24" style={{ color: "#aabdc7" }}>
        All mandatory fields are makred by *
      </p>
      <div>
        <div className="body-regular-gray3 mb-16">
          <p className="mb-16 caption-1-heavy-primary">Instructions</p>
          <p>
            1. First add ticket at base price. For free conferences, set base
            ticket price as zero.
          </p>
          <p>
            2. After base price ticket is created, one can add additional
            tickets at different prices.
          </p>
          <p>
            3. Confemy has universal refund policy. Click on the link below to
            see the refund policy.
          </p>
        </div>
        <div className="mb-40 body-regular-gray3">
          <Link
            // onClick={() => openInNewTab("/cancellation-refund")}
            onClick={() => setOpenRefundPolicy(true)}
            className="mb-16 caption-1-heavy-primary"
          >
            Refund Policy
          </Link>
        </div>
        {newConference?.tickets?.length > 0 ? (
          <div>
            <div className="mb-72">
              <div className="conftickets-table-wrap mb-24">
                <h4 className="color-primary mb-16">My tickets</h4>
                <table>
                  <thead>
                    <tr>
                      <th>Ticket</th>
                      <th>QTY</th>
                      <th>Price</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newConference?.tickets?.map((ticket) => {
                      return (
                        <tr key={ticket._id}>
                          <td
                            style={{ cursor: "pointer" }}
                            className="cell-hyphens"
                            onClick={() => editTicket(ticket)}
                          >
                            {ticket.name}
                          </td>
                          <td>{ticket.quantity}</td>
                          <td>
                            {ticket.currency && ticket.price === 0
                              ? "FREE"
                              : `${ticket.currency} ${ticket.price}`}
                          </td>
                          <td>
                            <span className="mr-4">
                              <i onClick={() => editTicket(ticket)}>
                                <EditIcon className="icon-size" />
                              </i>
                            </span>
                            <span>
                              <i
                                onClick={() => {
                                  setTicket(ticket);
                                  setDeleteDialogue(true);
                                }}
                              >
                                <DeleteIcon
                                  className="icon-size"
                                  fill="#757575"
                                />{" "}
                              </i>
                            </span>
                            {/* <span>
                              <i onClick={() => editTicket(ticket)}>
                                <OpeneyeIcon className="icon-size" />
                              </i>
                            </span> */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <button
                type="button"
                className="button-outlined button-outlined-primary p-8"
                onClick={openTicketModal}
              >
                + Add more tickets
              </button>
            </div>
            <div className="submit-cancel-loader-wrap">
              <button
                onClick={() => handleNext()}
                className="button button-primary "
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <div style={{ width: "100%", maxWidth: 600 }}>
            <TicketForm onClose={closeTicketModal} />
          </div>
        )}
      </div>
      {open && (
        <ModalX onDismiss={closeTicketModal} open={open}>
          <div className="ticket-form-container pl-24 pr-24 pb-32">
            <TicketForm onClose={closeTicketModal} ticket={ticketToEdit} />
          </div>
        </ModalX>
      )}
      {openRefundPolicy && (
        <ModalX
          onDismiss={() => setOpenRefundPolicy(false)}
          open={openRefundPolicy}
        >
          <div className="modalx-container-md  pl-24 pr-24 pb-32">
            <RefundPolicy />
          </div>
        </ModalX>
      )}
      {openDeleteDialogue && (
        <Dialogue
          msg="Are you sure you want to delete?"
          title="Delete ticket"
          closeDialogue={() => {
            setDeleteDialogue(false);
          }}
          yesAction={() => deleteTicket()}
        />
      )}
    </div>
  );
}
