import LikeInactiveIcon from '../icons/LikeInactiveIcon';
import playIcon from '../../assets/play.png';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  getPrice,
  likeMedia,
  openInNewTab,
  unLikeMedia,
} from '../../utility/commonUtil';
import LikeBlueIcon from '../icons/LikeBlueIcon';
import { useDispatch, useSelector } from 'react-redux';
import { alertAction } from '../../redux/alert/alertAction';
import { useEffect, useState } from 'react';
import PodcastTabs from '../tabs/PodcastTabs';

export default function PodcastDetailsCard({ podcast, playPreview, preview }) {
  const [isLiked, setIsLiked] = useState(false);
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = auth;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const like = (mediaId, userId, setIsLiked, type) => {
    if (!isLiked && isAuthenticated && user) {
      likeMedia(mediaId, userId, setIsLiked, type);
    } else {
      dispatch(alertAction('Please login to like a podcast.', 'info'));
    }
  };

  const unlike = (confId, userId, setIsLiked, type) => {
    if (isLiked && isAuthenticated && user) {
      unLikeMedia(confId, userId, setIsLiked, type);
    } else {
      dispatch(alertAction('Please login to unlike a podcast.', 'info'));
    }
  };

  useEffect(() => {
    setIsLiked(podcast?.liked);
  }, [podcast?.isLiked]);
  return (
    <div className="course-details-card ">
      <div className="ce-banner-wrap position-relative mb-24">
        {podcast?.banner?.map((item, indx) => (
          <img
            key={indx}
            className="ce-banner-img"
            alt="event banner"
            src={item?.Location}
            // Revoke data uri after image is loaded
            onLoad={() => {
              // URL.revokeObjectURL(image.Location);
            }}
          />
        ))}
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => playPreview()}
          className="play-button-circle-wrap"
        >
          <div className="play-button-circle">
            <img src={playIcon} className="icon-sm" />
          </div>
          <div className="play-preview-text">Podcast preview</div>
        </div>
      </div>
      {podcast?.monthlyPodcastPrice?.price > 0 ||
      podcast?.yearlyPodcastPrice?.price > 0 ? (
        <>
          {' '}
          <div>
            <PodcastTabs
              tabs={[
                {
                  label: `${podcast?.currencySymbol} ${podcast?.monthlyPodcastPrice?.actualPrice} monthly`,
                  path: preview
                    ? `/podcast/preview/${podcast?._id}/monthly`
                    : `/podcast/${podcast?._id}/monthly`,
                },
                {
                  label: `${podcast?.currencySymbol} ${podcast?.yearlyPodcastPrice?.actualPrice} yearly`,
                  path: preview
                    ? `/podcast/preview/${podcast?._id}/yearly`
                    : `/podcast/${podcast?._id}/yearly`,
                },
              ]}
              showRadioButtons={false}
            />
          </div>
          <div>
            <Outlet />
          </div>
        </>
      ) : (
        <div
          className="flex-vc caption-2-bold-cblack "
          style={{
            fontSize: 16,
            gap: '8px',
            wordBreak: 'break-word',
            marginBottom: '100px',
          }}
        >
          {getPrice(
            podcast?.currency,
            podcast?.monthlyPodcastPrice?.actualPrice,
            podcast?.monthlyPodcastPrice?.price,
            podcast?.currencySymbol
          )}
        </div>
      )}
      <div className="flex-vc">
        <button
          className="small-button small-button-green mr-10"
          style={{ flexGrow: 1, height: 54 }}
          // onClick={() => onPublish()}
          onClick={() => navigate(`/podcast/booking-price/${podcast?._id}`)}
        >
          Book
        </button>
        <span className="primary-color-box " style={{ cursor: 'pointer' }}>
          {isLiked ? (
            <i
              className="conference-card-buttons"
              onClick={() =>
                unlike(podcast?._id, user?._id, setIsLiked, podcast?.type)
              }
            >
              {/* <LikeRedIcon className="icon-sm" /> */}
              <LikeBlueIcon className="icon-lg" />
            </i>
          ) : (
            <i
              className="conference-card-buttons"
              onClick={() =>
                like(podcast?._id, user?._id, setIsLiked, podcast?.type)
              }
            >
              <LikeInactiveIcon className="icon-lg" />
            </i>
          )}
        </span>
      </div>
    </div>
  );
}
