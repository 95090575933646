import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  alertAction,
  localAlertAction,
} from "../../../redux/alert/alertAction";
import SingleImageUploader from "../../image-uploader/SingleImageUploader";

import api from "../../../utility/api";
import TextError from "../../formik/TextError";
import DeleteIcon from "../../icons/DeleteIcon";

import { loadMyOrganizationsSelectListAction } from "../../../redux/organization/myOrganizationsAction";
import { loadUserSpeakers } from "../../../redux/user-profile/userProfileAction";
import LocalAlert from "../../alert/LocalAlert";
import SubmitButtonWithLoader from "../../button/SubmitButtonWithLoader";
import SelectFormType1 from "../../reselect/SelectFormType1";
import { modalStyleformType1 } from "../../reselect/selectUtil";
import Select from "react-select";
import { getValue } from "../../../utility/commonUtil";
import "../../speaker/speakerForm.styles.scss";

const validationSchema = yup.object({
  firstName: yup.string().max(25).trim().required("Required"),
  lastName: yup.string().max(25).trim().required("Required"),
  degree: yup.string().trim().required("Required"),
  designation: yup.string().trim().required("Required"),
  host: yup.string().when("editMode", {
    is: false,
    then: yup
      .string()
      .required("Required. Choose speaker is for my self or user"),
  }),
});

export default function SpeakerForm({
  setShowSpeakerForm,
  page,
  setCount,
  editData,
  editMode,
  filter,
  searchText,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const initialValues = {
    images: editMode ? editData?.images : [],
    firstName: editMode ? editData?.firstName : "",
    lastName: editMode ? editData?.lastName : "",
    degree: editMode ? editData?.degree : "",
    designation: editMode ? editData?.designation : "",
    deletedImage: [],
    editMode: editMode ? true : false,
    host: "",
  };

  async function onSubmit(values, actions) {
    const formData = {
      speakerDetails: {
        data: values.images,
        firstName: values.firstName,
        lastName: values.lastName,
        degree: values.degree,
        designation: values.designation,
        createdBy: values?.host === "user" ? "user" : "organization",
        organizationId: values?.host != "user" ? values?.host : null,
        userId: user._id,
        deletedImage: values.deletedImage,
      },
    };

    if (values.images?.length > 0 && !values.images[0]?.Key) {
      const formDataObj = new FormData();
      formDataObj.append("file", values.images[0]);
      try {
        const imagesResponse = await api.post("fileUploads", formDataObj);
        if (imagesResponse) {
          formData.speakerDetails.data = imagesResponse.data.data;
          let response;
          if (editMode) {
            response = await api.patch(
              `/speakers/${editData?._id}?page=${page}&&limit=5&&_id=${user?._id}&&asset=true&&filter=${filter}&&searchText=${searchText}`,
              formData
            );
          } else {
            response = await api.post(
              `/speakers?page=${page}&&limit=5&&_id=${user?._id}&&asset=true&&filter=${filter}&&searchText=${searchText}`,
              formData
            );
          }

          if (response) {
            setCount(response.data.data.speakerCount);
            dispatch(loadUserSpeakers(response.data.data.speakers));
            actions.resetForm({ values: initialValues });
            setShowSpeakerForm(false);
          }
        }
      } catch (err) {
        // actions.setFieldError("logos", err.response?.data.message);
        dispatch(
          localAlertAction(
            err?.response?.data?.message || "Something went wrong",
            "danger"
          )
        );
      }
    } else {
      try {
        let response;

        if (editMode) {
          response = await api.patch(
            `/speakers/${editData?._id}?page=${page}&&limit=5&&_id=${user?._id}&&asset=true&&filter=${filter}&&searchText=${searchText}`,
            formData
          );
        } else {
          response = await api.post(
            `/speakers?page=${page}&&limit=5&&_id=${user?._id}&&asset=true&&filter=${filter}&&searchText=${searchText}`,
            formData
          );
        }
        if (response) {
          setCount(response.data.data.speakerCount);
          dispatch(loadUserSpeakers(response.data.data.speakers));
          actions.resetForm({ values: initialValues });
          setShowSpeakerForm(false);
        }
      } catch (err) {
        if (err) {
          dispatch(
            localAlertAction(
              err?.response?.data?.message || "Something went wrong",
              "danger"
            )
          );
        }
      }
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  function setFormikFieldValue(field, value) {
    formik.setFieldValue(field, value);
  }
  const organizationsListForSelect = useSelector(
    (state) => state.myOrganizations.organizationsListForSelect
  );
  const options2 = [
    { value: "user", label: "User" },
    ...organizationsListForSelect,
  ];

  const loadMyOrgnizations = async (id) => {
    const url = `organizations/users/${id}?orgForConference=true`;
    try {
      const response = await api.get(url);

      if (response) {
        dispatch(
          loadMyOrganizationsSelectListAction(response.data?.data?.organization)
        );
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  useEffect(() => {
    loadMyOrgnizations(user?._id);
  }, [user?._id]);
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      formik.values.images?.forEach((file) =>
        URL.revokeObjectURL(file.Location)
      );
  }, [formik.values.images]);

  return (
    <>
      <h2 className="color-primary text-align-center mb-24">Add Speaker</h2>
      <LocalAlert />
      <form
        className="form-type-1"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <div className="speaker-dzimg-container mb-40">
          {formik.values?.images?.length > 0 ? (
            formik.values.images?.map((image) => (
              <div key={image.Location} className="speakerimage-container">
                <div className="speakerimage-wrap">
                  <img
                    className="speakerimage"
                    alt="speaker image"
                    src={image.Location}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                      URL.revokeObjectURL(image.Location);
                    }}
                  />
                </div>
                <div className="speakerimage-overlay"></div>
                <div
                  onClick={() => {
                    formik.values.images.forEach((img) => {
                      if (img?.Key) {
                        formik.setFieldValue("deletedImage", [img]);
                      }
                    });

                    const imagesLeft = formik.values.images?.filter(
                      (e) => e !== image
                    );
                    formik.setFieldValue("images", imagesLeft);
                  }}
                  className="speakerimage-delete-circle"
                >
                  <DeleteIcon className="icon-size" />
                </div>
              </div>
            ))
          ) : (
            <div className="speaker-image-dropzone-wrap">
              <SingleImageUploader
                fieldName="images"
                setFormikFieldValue={setFormikFieldValue}
              />
            </div>
          )}
        </div>
        {!editMode && (
          <div>
            <h4
              className="color-primary mb-16 px-8 text-align-center"
              style={{ fontFamily: "Avenir", fontWeight: "normal" }}
            >
              Pick host to which speaker belongs*
            </h4>
            <div>
              <Select
                value={getValue(options2, formik.values.host, false)}
                options={options2}
                placeholder="Select org or User*"
                name="filter"
                onChange={(option) => {
                  formik.setFieldValue("host", option.value);
                }}
                menuPortalTarget={document.body}
                styles={modalStyleformType1}
              />
            </div>
            <div className="mb-10">
              {formik.touched.host && Boolean(formik.errors.host) && (
                <TextError>{formik.errors.host}</TextError>
              )}
            </div>
          </div>
        )}
        <div className="speaker-form-grid">
          <div>
            <div className="material-textfield">
              <input
                id="firstName"
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                placeholder=" "
              />
              <label>First Name*</label>
            </div>
            <div>
              {formik.touched.firstName && Boolean(formik.errors.firstName) && (
                <TextError>{formik.errors.firstName}</TextError>
              )}
            </div>
          </div>
          <div>
            <div className="material-textfield">
              <input
                id="lastName"
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                placeholder=" "
              />
              <label>Last Name*</label>
            </div>
            <div>
              {formik.touched.lastName && Boolean(formik.errors.lastName) && (
                <TextError>{formik.errors.lastName}</TextError>
              )}
            </div>
          </div>
          <div>
            <div className="material-textfield">
              <input
                id="degree"
                type="text"
                name="degree"
                value={formik.values.degree}
                onChange={formik.handleChange}
                placeholder=" "
              />
              <label>Degree*</label>
            </div>
            <div>
              {formik.touched.degree && Boolean(formik.errors.degree) && (
                <TextError>{formik.errors.degree}</TextError>
              )}
            </div>
          </div>
          <div>
            <div className="material-textfield">
              <input
                id="designation"
                type="text"
                name="designation"
                value={formik.values.designation}
                onChange={formik.handleChange}
                placeholder=" "
              />
              <label>Designation*</label>
            </div>
            <div>
              {formik.touched.designation &&
                Boolean(formik.errors.designation) && (
                  <TextError>{formik.errors.designation}</TextError>
                )}
            </div>
          </div>

          <div>
            {/* <button
              style={{ width: '100%' }}
              className="button button-green"
              type="submit"
            >
              Submit
            </button> */}
            <SubmitButtonWithLoader
              isSubmitting={formik.isSubmitting}
              text={"Submit"}
              className="button button-green"
              fullWidth={true}
            />
          </div>
          <div>
            <button
              style={{ width: "100%" }}
              className="button button-primary"
              type="button"
              onClick={() => setShowSpeakerForm(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
