import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BdfoCounts from "../../components/bookings-for-organizer/BdfoCounts";

import BdfoConfTitle from "../../components/bookings-for-organizer/BdfoConfTitle";
import BdfoLinks from "../../components/bookings-for-organizer/BdfoLinks";
import OrgBookingTable from "../../components/bookings-for-organizer/OrgBookingTable";
import ConfDetailsActionButtons from "../../components/bookings-for-organizer/ConfDetailsActionButtons";
import SalesByTickets from "../../components/bookings-for-organizer/SaleByTickets";
import Loader from "../../components/loader/Loader";
import { alertAction } from "../../redux/alert/alertAction";
import api from "../../utility/api";

import "./bdfoPage.styles.scss";
// booking details for organizer
export default function BdfoPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState(null);

  const dispatch = useDispatch();
  const confId = useParams().confId;

  async function getBookings(conferenceId) {
    try {
      const response = await api.get(
        `organizers/earnings/conferences/${conferenceId}`
      );
      setDetails(response.data.data.earnings);
      setIsLoading(false);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
      // setIsLoading(false);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getBookings(confId);
  }, [confId]);
  console.log({ details });
  return (
    <div className="container pt-64">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="bdfo-container">
            <ConfDetailsActionButtons confDetails={details?.confDetails} />
            <section className="mb-32">
              <BdfoConfTitle
                title={details?.confDetails?.title}
                startDate={details?.confDetails?.startDate}
                timeZone={details?.confDetails?.timezone}
              />
            </section>
            <section className="mb-32">
              <BdfoCounts
                ticketDetails={details?.ticketDetails}
                tickets={details?.tickets}
                soldTickets={details?.soldTickets}
                grossPrice={details?.grossPrice}
                refundRequests={details?.refundRequests}
                refunded={details?.refunded}
                currencySymbol={details?.confDetails?.currencySymbol}
              />
            </section>
            <SalesByTickets
              confDetails={details?.confDetails}
              ticketDetails={details?.ticketDetails}
            />
            <section className="mb-60">
              <OrgBookingTable
                getBookings={getBookings}
                attendees={details?.attendees}
                conf={details?.confDetails}
              />
            </section>
            <section>
              <BdfoLinks confId={confId} />
            </section>
          </div>
        </>
      )}
    </div>
  );
}
